<template>
  <div>
    <!-- Icons & Email -->
    <div class="header" >
      <div class="container pt-3"  >
        <div class="row">
          
          <div class="col-xs-3 col-sm-4 col-lg-6">
            <div class="icons" >
              <a  :href="data.link" target="_blank"  v-for="(data, index) in products" :key="index"
                ><i v-if ="data.position=='left'" :class="data.icon"></i>
            
        </a>
            </div>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-8 col-lg-6">
            <div class="emailer">
              <p v-for="(data, index) in products" :key="index" >
              <span v-if ="data.position=='right'" > &nbsp; &nbsp;{{ data.name }}&nbsp;:&nbsp;{{ data.value }} </span></p>
       
            </div>
          </div>
        </div>
        </div>
     
    </div>

    <!-- Logo & Search -->
    <!-- <div class="navbar navbar-expand-lg text-bg-white">
      <div class="container col col-md-4 col-lg-6 col-xl-8">
        <div class="logo">
          <a href="#"><img src="/pphlogo.png" alt="Logo" /></a>
        </div>
        <div class="search">
          <form class="form1" role="search">
            <input
              class="form-control mr-2"
              type="search"
              placeholder="What are you looking for?"
              aria-label="Search"
            />
            <button class="btn-search" type="submit">Search</button>
          </form>
        </div>
      </div>
    </div> -->






    <!-- Navigation Menu -->

</div>
</template>

<script>

import apis from "@/apis";
export default ({
  data() {

    return{
      products: [],
    };
  },
    methods: {
      Getsocialmenu() {
            var data = { count: false };
            var promise = apis.GetContactList(data);
            promise.then((response) => {
                //console.log("Getsocialmenu",response.data.data);
                this.products = response.data.data;
            });
        },
    },
    
    mounted() {
    this.Getsocialmenu();
  },

  
})
</script>


<style lang="scss" scoped>
.header {
  background-color: #0f75bc;
 
}
// .top-nav {
//   background-color: #0f75bc;
//   height: 56px;
//   display: flex;
//   position: relative;
//   color: #ffff;
//   padding-top: 1rem;
// }

.top-nav icons {
  background-color: #0f75bc;
  height: 56px;
  display: flex;
  justify-content: start;
  color: #ffff;
  padding-top: 1rem;
}

.form1 {
  // width: 500px !important;
  // background-color: #eeeeee;
  display: flex;
  flex-direction: row;
  width: 800px;
}

.form-control{
  width:500px;
  background-color: #eee;

}
.btn-search {
  background-color: #2e3192;
  color: #fff;
  width: 120px;
  height: 50px;
  border-radius: 10px;
  border: none;
}
.banner {
  background-repeat: no-repeat;
  background-size: auto;
  width: 2000px;
}

a {
  color: #fff;
}

.icons {
  display: flex;
  gap:10px;
}

// .search{
//   display: flex;
//   flex-direction: row;
// }

.btn-blue{
  background-color:#2E3192;
  color:#fff;

}
.navbar2{
  display: flex;
  justify-content:space-evenly;
  position: -webkit-sticky;
    position: sticky;
    top: 80px;
    z-index: 100;
}
.emailer {
  display: flex;
 
  color:#eeeeee;

  position: relative;
  justify-content: end;
  
}




@media only screen and (max-width: 600px) {
  .form1 {
    width: 100%;
  }
  .form-control{
    width:100%;
 
  }

  .pphlogo{
    width: 100px;
    margin-top: 2px;
  }

.icons{
  justify-content: center;
  padding-left: 33px;

}
.emailer{

  justify-content: center;
  font-size:12px;
}

}

@media only screen and (min-width: 768px) {
  .form1 {
    width: 100%;
  }



.form-control{
    width:100%;
 
  }

}

</style>
