<template>
<div class="mt-3">
    <Loader v-if="loading" />
    <div class="container">
        <div class="row">
            <ol class="breadcrumb mt-4">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Details</li>
            </ol>
        </div>
    </div>
    <!--left side accordion-->

    <div class="container">
        <div class="row">
            <!---right side -->

            <div class="container mb-3" v-for="(product, index) in products" :key="index">
                <div class="row g-0">
                    <div class="col-md-4">
                        <div class=" card ">
                            <div class="col-6">
                                <Badge v-if=" product.discount && product.discount > 0" style="margin-top: -10px;color: black;" :value="product.discount + '% off'" severity="warning"></Badge>
                                <span v-else><br></span>
                            </div>
                            <Galleria id="zoomC" v-if="product.images.length>0" :value="product.images" :responsiveOptions="responsiveOptions" :numVisible="3" containerStyle="max-width: 1000px" thumbnailsPosition="left">
                                <template #item="slotProps">
                                    <!-- <div class="img_producto_container" id="zoom" @mousemove="handleMouseMove" @mouseleave="resetZoom"> -->
                                    <img class="img_producto" style="transform: imgTransform " :zoom-src="slotProps.item" :src="slotProps.item" />
                                    <!-- </div> -->
                                    <!-- <div v-show="isZoomed" class="magnified-image" :style="{ backgroundImage: 'url(' + currentImage + ')' }"></div> -->
                                </template>
                                <template #thumbnail="slotProps">

                                    <img :src="slotProps.item" style="width: 50px;" />

                                </template>
                            </Galleria>
                            <img v-else :src="product.image" style="width: 50%" />

                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card-body mt-5 ml-6">
                            <div>
                                <h3 class="title_pro" @click="gotoLink(product.url)">{{ product.title }}</h3>

                                <Rating class="rating" v-model="product.total_ratings" readonly :cancel="false" />
                                <div class="review-links">
                                    Total Reviews : {{ product.total_review }}

                                </div>

                                <p>
                                    <span v-if="
                            product.actual_price < product.price ||
                            product.actual_price > product.price
                          ">
                                        <b>
                                            ₹ {{ FormatService.FormatAmount(product.price) }}/-</b>
                                        <s style="font-weight: 100; font-size: 12px">M.R.P ₹
                                            {{
                              FormatService.FormatAmount(product.actual_price)
                            }}/-</s>
                                        ( {{ product.discount }}% off )</span>
                                    <b v-else>
                                        ₹ {{ FormatService.FormatAmount(product.price) }}/-</b>
                                </p>
                                <p class="custname ml-2">
                                    by
                                    <span class="text-primary" v-if="product.site_master.length > 0">{{
                              product.site_master[0]["name"]
                            }}</span>
                                </p>
                                <p class="card-text1">
                                    <small class="text-body-secondary">{{product.model}}</small>
                                    <!-- <img src="/logo-img.jpg"/>  -->
                                </p>

                                <Button class="button-buy" label="Buy Now" @click="gotoLink(product.url)"></button>&nbsp;
                                <Button label="Know More" @click="gotoLink(product.url)" severity="success"></Button>



                                <caption style="font-size:20px; font-weight: bold; padding: 10px; width: 400px; margin-top:10px">Other Deals</caption>
                                <div class="deal col-4 col-lg-6 col-md-6 col-sm-12" v-for="(compaire_list,index1) in product.compare_list" :key="index1" @click="toggleContent(compaire_list)">

                                    <p>
                                        <img :src="compaire_list.image" style="width : 50px; height: 50px ">
                                        {{ FormatService.FormatTitle(compaire_list.title,20) }}
                                        <Rating class="rating" v-model="product.total_ratings" readonly :cancel="false" />
                                    </p>
                                 
                                    <p>
                                        Total Reviews : {{ product.total_review }}        
                                    </p>
                                    <p>
                                    <p style="font-weight:bold">₹ {{ FormatService.FormatAmount(compaire_list.price) }}/- </p> 
                                        by
                                        <span class="text-primary" v-if="compaire_list.site_master">{{
                                  compaire_list.site_master[0]["name"] }}</span></p>
                                      <button class="btn btn-primary btn-md" @click="toggleContent(compaire_list)">
                                                View</button> &nbsp;
                                                   <button class="btn btn-primary btn-md" @click="gotoLink(compaire_list.url)">
                                                Buy
                                            </button>
                                            <br/>
                                </div>
                           

                                <!-- <table class="table_deals col-12 col-lg-6 col-sm-4" style="padding:10px; border: 1px solid #000; border-radius:20px;background-color:#fff ">
                                    <caption style="caption-side: top; font-size:20px;font-weight: bold; padding: 10px;">Other Deals</caption>

                                    <p>

                                    </p>
                                    <tr v-for="(compaire_list,index1) in product.compare_list" :key="index1">
                                        <td>
                                            <img :src="compaire_list.image" style="width : 50px; height: 50px ">
                                        </td>
                                        <td>
                                            {{ FormatService.FormatTitle(compaire_list.title,20) }}
                                            <Rating class="rating" v-model="product.total_ratings" readonly :cancel="false" />
                                            <div class="review-links">
                                                Total Reviews : {{ product.total_review }}

                                            </div>
                                        </td>
                                        <td>
                                            ₹ {{ FormatService.FormatAmount(compaire_list.price) }}/-
                                        </td>
                                        <td>
                                            by
                                            <span class="text-primary" v-if="compaire_list.site_master">
                                            {{compaire_list.site_master[0]["name"] }}</span>
                                        </td>
                                        <td>
                                            <button class="btn btn-primary btn-sm" @click="toggleContent(compaire_list)">
                                                View</button>
                                        </td>
                                        <td>
                                            <button class="btn btn-primary btn-sm" @click="gotoLink(compaire_list.url)">
                                                Buy
                                            </button>
                                        </td>

                                    </tr>
                                </table> -->
                            </div>
                            <br />

                        </div>
                        <!-- {{ product.compare_list }} -->

                    </div>
                </div>
                <Adsense style="width: 100%; min-width: 250px" ins-style="display:inline-block;width:728px;height:90px" data-ad-client="ca-pub-0123456789101112" data-ad-slot="9876543210">
                </Adsense>
                <TabView>
                    <TabPanel header="DESCRIPTION">
                        <p style="text-indent: 50px;">{{ products[0].description }}</p>
                    </TabPanel>
                    <TabPanel header="SPECIFICATION">
                        <!-- {{ products[0].specification_list }} -->
                        <DataTable :value="products[0].specification_list" responsiveLayout="scroll">
                            <Column field="name" header="Name" :sortable="true" style="width: 20%;font-weight: bold;"></Column>
                            <Column field="description" header="Description" :sortable="true" style="width: 80%"></Column>
                        </DataTable>
                    </TabPanel>

                    >
                </TabView>

            </div>

        </div>
    </div>
</div>
</template>

<script>
import apis from "@/apis";
import Loader from "../components/Loader.vue";
import FormatService from "../service/FormatService";
import PhotoService from "../service/PhotoService";

export default {
    el: 'zoom',
    data() {
        return {

            // Other data properties remain unchanged
            isZoomed: false,
            zoomTransform: "scale(1)",
            magnifyFactor: 2,

            imgTransform: "scale(1)",
            imageSrc: "slotProps.item",
            FormatService: null,
            loading: false,
            zoom: false,
            zoomPosition: '0% 0%',

            images: [{
                    src: "https://cdn.mdcomputers.in/image/cachewebp/catalog/mouse/razer/rz01-03730400-r3m1/rz01-03730400-r3m1-image-main-300x300.webp",
                },
                {
                    src: "https://cdn.mdcomputers.in/image/cachewebp/catalog/mouse/razer/rz01-03730400-r3m1/rz01-03730400-r3m1-image-main-300x300.webp",
                },
                {
                    src: "https://cdn.mdcomputers.in/image/cachewebp/catalog/mouse/razer/rz01-03730400-r3m1/rz01-03730400-r3m1-image-main-300x300.webp",
                },
                {
                    src: "https://cdn.mdcomputers.in/image/cachewebp/catalog/mouse/razer/rz01-03730400-r3m1/rz01-03730400-r3m1-image-main-300x300.webp",
                },
            ],

            first: [0],

            value: [0, 100],

            selectedCity: "",
            cities: [{
                    name: "Sort by popularity"
                },
                {
                    name: "Sort by latest"
                },
                {
                    name: "Sort by Price:low to high"
                },
                {
                    name: "Sort by Price:high to low"
                },
            ],

            showContent: false,

            products: [],

            responsiveOptions: [{
                    breakpoint: "1300px",
                    numVisible: 4,
                },
                {
                    breakpoint: "575px",
                    numVisible: 1,
                },
            ],
        };
    },
    props: ['id', 'title'],
    methods: {
        handleMouseMove(event) {
            const {
                left,
                top,
                width,
                height
            } = event.target.getBoundingClientRect();
            const x = (event.clientX - left) / width;
            const y = (event.clientY - top) / height;
            this.zoomTransform = `scale(${this.magnifyFactor}) translate(-${x * (this.magnifyFactor - 1)}px, -${y * (this.magnifyFactor - 1)}px)`;
            this.isZoomed = true;
        },
        resetZoom() {
            this.isZoomed = false;
            this.zoomTransform = "scale(1)";
        },

        scaleImage(event) {
            const scale = event.currentTarget.dataset.scale;
            this.imgTransform = `scale(${scale})`;
        },
        resetImage() {
            this.imgTransform = "scale(1)";
        },

        moveImage(event) {
            const x = ((event.pageX - event.currentTarget.offsetLeft) / event.currentTarget.offsetWidth) * 100;
            const y = ((event.pageY - event.currentTarget.offsetTop) / event.currentTarget.offsetHeight) * 100;
            this.imgTransformOrigin = `${x}% ${y}%`;
        },

        // toggleContent() {
        //     this.showContent = !this.showContent;
        // },
        toggleContent(product) {
            this.$router.replace({
                name: "pphprodetails",
                params: { id: product._id, title: product.title },
            });
            this.$router.go(0);
        },
        gotoLink(link) {
            window.open(link);
        },
        async GetList() {
            var data = {
                count: false,
                limit: 1,
                page_no: 1,
                _id: this.id,
                title: this.title
            };
            this.loading = true;
            var promise = apis.GetProductList(data);
            promise.then((response) => {
                this.loading = false;
                this.products = response.data.data;
                window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Optional: smooth scrolling animation
                });

            });
        },

    },

    components: {
        //selectedCity, cities, isVisible, products, toggleElement
        Loader,

    },

    async created() {
        this.FormatService = new FormatService();
        this.photoService = new PhotoService();
        await this.GetList();
    },
    async mounted() {
        this.photoService.getImages().then((images) => {
            // this.images = images;
        });

    }
};
</script>

<style scoped>
.deal {
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        display: inline-block;
    margin-right: 12px;
        padding: 10px;
}

.magnified-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    /* Adjust the width of the magnified image as needed */
    height: 200px;
    /* Adjust the height of the magnified image as needed */
    border: 1px solid #ccc;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 9999;
    pointer-events: none;
}

.breadcrumb {
    display: flex;
}

#myDIV {
    /* width: 100%;
    padding: 50px 0;
    text-align: center;
    background-color: lightblue; */
    margin-top: 20px;
}

.btn-search {
    background-color: #2e3192;
    color: #fff;
    width: 120px;
    height: 50px;
    border-radius: 10px;
    border: none;
}

.search {
    border-radius: 15px;
    background-color: #eee;
    border: white;
    height: 30px;
    width: 150px;
    padding: 1rem;
    margin-right: 1rem;
}

.badge1 {
    z-index: 99999;
    display: inline-block;
    background-color: #ff8551;
    padding: 5px;
    position: fixed;
    align-items: start;
    /* text-align: start; */
    top: 0;
    width: 50px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 1rem;

    margin-top: 28px;
}

.rating {
    color: #ff8551 !important;
}

.form-check {
    line-height: 28px;
}

.form-check .form-check-input {
    margin-top: 7px;
}

/* Support */
.support-box {
    top: 2rem;
    position: relative;
    bottom: 0;
    text-align: center;
    display: block;
}

.b-btn {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.b-btn.paypal i {
    color: blue;
}

.b-btn:hover {
    text-decoration: none;
    font-weight: bold;
}

.b-btn i {
    font-size: 20px;
    color: yellow;
    margin-top: 2rem;
}

.dropdown {
    margin-right: 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.dropdown h6 {
    margin-right: 1.75rem;
    display: flex;
}

.pageno {
    display: flex;
    justify-content: center;
}

.p-inputtext {
    width: 100% !important;
}

.card-img {
    width: 70%;
}

.card-text1 {
    display: flex;
    justify-content: space-between;
}

.button-buy {
    background-color: #2e3192;
}

/*zoom img*/

.img_producto_container {
    width: 250px;
    /* height: 300px; */
    position: relative;
    overflow: hidden;
    cursor: zoom-in;
}

.img_producto {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 280px;
    /* width: 100%;
  height: 100%; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform 0.5s ease-out;
}

@media only screen and (max-width: 768px) {
    .breadcrumb {
        margin-left: 20px;
    }

    .dropdown h6 {
        display: none;
    }

    .card-img {
        width: auto;
    }

    h3.title_pro {
        font-size: 15px;
        font-weight: 600;
    }

}

@media screen and (max-width: 600px) {

    .details-pro {
        justify-content: center;
    }

    .details-pro h6 {
        display: flex;
        justify-content: center;

    }
    .deal{
      display:block;
      width:100%;
      margin-top:10px;
      
    }

}
</style>
